import styled from 'styled-components'

export default styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #121212e8;
  z-index: 10;
  .linear-activity-container {
    overflow: hidden;
    width: 30%;
    height: 6px;
    background-color: #f38283;
    margin: 20px auto;
    border-radius: 5px;
    .indeterminate {
      position: relative;
      width: 100%;
      height: 100%;
      &::before {
        content: '';
        position: absolute;
        height: 100%;
        background-color: #e63133;
        animation: indeterminate_first 1.5s infinite ease-out;
      }
      &::after {
        content: '';
        position: absolute;
        height: 100%;
        background-color: #981214;
        animation: indeterminate_second 1.5s infinite ease-in;
      }
    }
  }
  @keyframes indeterminate_first {
    0% {
      left: -100%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }

  @keyframes indeterminate_second {
    0% {
      left: -150%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }
`
