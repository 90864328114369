import React from 'react'
import PropTypes from 'prop-types'

import LoadingStyled from './styled'

class Loading extends React.PureComponent {
  render() {
    return (
      <LoadingStyled id={this.props.id} data-test={this.props.id} className={this.props.className}>
        <div className="linear-activity-container">
          <div className="indeterminate"></div>
        </div>
      </LoadingStyled>
    )
  }
}

Loading.defaultProps = {
  id: 'loading-component',
  className: '',
}

Loading.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
}

export default Loading
